import styled from "styled-components";
import { categories } from "../data";
import CategoryItem from "./CategoryItem";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  
  @media screen and (max-width:480px){
    padding: 0px;
    flex-direction: column;
  }

`;

const Categories = () => {
  return (
    <Link href="http://localhost:3000/products/women">
    <Container>
      {categories.map((item) => (
        <CategoryItem item={item} key={item.id} />
      ))}
    </Container>
    </Link>
  );
};

export default Categories;
